<template>
  <div class="mobileHiFi">
    <div class="imgBox">
      <img src="../../assets/mobile/product/hifi.png" class="img" />
    </div>
    <div class="param">
      <div class="title">Hi-Fi蓝牙功放</div>
      <div class="tit">相关参数</div>
      <div class="text">
        <div class="left">产品型号:</div>
        <div class="right">LS-BT-AMP01A</div>
      </div>
      <div class="text">
        <div class="left">额定输入:</div>
        <div class="right">AC200V~250V/50Hz</div>
      </div>
      <div class="text">
        <div class="left">蓝牙版本:</div>
        <div class="right">蓝牙4.2</div>
      </div>
      <div class="text">
        <div class="left">功放额定输出:</div>
        <div class="right">2×50W（8Ω）</div>
      </div>
      <div class="text">
        <div class="left">功放频率响应:</div>
        <div class="right">22Hz~20KHz±3dB</div>
      </div>
      <div class="text">
        <div class="left">功放失真度:</div>
        <div class="right">0.05%@1KHz,1W</div>
      </div>
       <div class="text">
        <div class="left">变压器功率:</div>
        <div class="right">环牛200W</div>
      </div>
      <div class="text">
        <div class="left">工作湿度:</div>
        <div class="right">0%-95%RH，无冷凝</div>
      </div>          
      <div class="text">
        <div class="left">工作频段（可选）:</div>
        <div class="right">433.0MHz~453.0MHz</div>
      </div>
      <div class="text">
        <div class="left">无线发射功率（可选）:</div>
        <div class="right">＜10dBm</div>
      </div>
    </div>
    <div class="paramDe">
      <div
        class="txt"
      >Hi- Fi蓝牙功放是一个大功率高品质的客房音乐播放功放，能够通过蓝牙连接手机或主机，接收音源发出的数字音频信号，同时驱动大功率高品质扬声器还原出清晰纯净的音乐。可配合主机实现音乐的音量、切换曲目等功能。</div>
      <div class="data">
        <img src="../../assets/mobile/dataImg.png" class="dataImg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  created() {}
};
</script>
<style scoped lang="less">
.mobileHiFi {
  background-color: #fff;
  .imgBox {
    width: 100%;
    padding-top: 0.8rem;
    padding-bottom:1.1rem;
    display: flex;
    justify-content: center;
    .img {
      width: 4.32rem;
      height: 2.52rem;
    }
  }
  .param {
    text-align: left;
    margin: 0 0.3rem;
    padding-bottom: 0.29rem;
    border-bottom: 1px solid #333;
    .title {
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: 400;
      color: #ff9000;
      padding-bottom: 0.2rem;
      border-bottom: 1px solid #333;
    }
    .tit {
      margin-top: 0.17rem;
      font-size: 0.36rem;
      line-height: 0.36rem;
      font-weight: bold;
      color: #333333;
      margin-bottom: 0.6rem;
    }
    .text {
      margin-top: 0.36rem;
      display: flex;
      font-size: 0.24rem;
      line-height: 0.24rem;
      font-weight: 400;
      color: #333333;
      &:first-of-type {
        margin-top: 0;
      }
      .left {
        width: 2.5rem;
        text-align: right;
        overflow: hidden; 
        text-overflow: ellipsis; 
        -o-text-overflow: ellipsis;
        white-space:nowrap;
      }
      .right {
        margin-left: 0.59rem;
      }
    }
  }
  .paramDe {
    margin-top: 0.3rem;
    padding: 0 0.3rem;
    .txt {
      font-size: 0.24rem;
      font-weight: 400;
      color: #333333;
      line-height: 0.4rem;
      text-align: justify;
    }
    .data {
      width: 6.45rem;
      margin-top: 0.39rem;
      padding-bottom: 0.8rem;
      display: flex;

      .dataImg {
        width: 100%;
        height: 0.18rem;
      }
    }
  }
}
</style>